<template>
    <Card class="mt-5 no-border no-padding AgendarHorariostep0">
        <template #title> Agendar horários <TourComponent tourName="AgendarHorarioGuiaGestaoMedica" /> </template>
        <template #content>
            <FormProcedimentosItems
                v-for="(funcionario, index) in funcionarios"
                v-model:funcionariosCarregando="funcionariosCarregando"
                :key="funcionario.id"
                :ref="'FormProcedimentosItems' + funcionario.id"
                :cidade="this.formData.cidade"
                :indexItem="index"
                :periodo="this.formData.periodo"
                :dataSugestaoFinal="this.intervaloSugeridoFinal"
                :dataSugestaoInicial="this.intervaloSugeridoInicial"
                :funcionario="funcionario"
                :submitted="submitted"
                :prestador="this.formData.prestador"
                :procedimentos="funcionario.procedimentos"
                @selecionouHorario="selecionouHorario(funcionario.id, $event)"
                :horariosSelecionados="horariosSelecionados"
            />
        </template>
        <template #footer>
            <div class="grid grid-nogutter justify-content-between">
                <Button :loading="isLoading" class="w-2 p-button-outlined AgendarHorariostep6" label="Voltar" @click="prevPage()" />
                <Button :loading="isLoading" class="w-2 AgendarHorariostep7" label="Avançar" @click="nextPage()" />
            </div>
        </template>
    </Card>
</template>

<script>
import FormProcedimentosItems from './FormProcedimentosItems.vue';
import TourComponent from '../../../../components/TourComponent';
export default {
    components: { FormProcedimentosItems, TourComponent },
    emits: ['prev-page', 'next-page', 'onConfirm', 'onClose'],
    props: {
        formData: Object,
        isLoading: Boolean
    },
    data() {
        return {
            submitted: false,
            exames: [],
            intervaloSugeridoInicial: null,
            intervaloSugeridoFinal: null,
            funcionariosCarregando: [],
            funcionarios: [],
            horariosSelecionados: {}
        };
    },
    activated() {
        if (this.formData.intervaloSugerido) {
            this.intervaloSugeridoInicial = this.formData.intervaloSugerido[0];
            this.intervaloSugeridoFinal = this.formData.intervaloSugerido[1];
        }
        this.funcionarios = [];
        this.carregarFuncionariosLimiteSOC();
    },
    watch: {
        funcionariosCarregando() {
            this.carregarFuncionariosLimiteSOC();
        }
    },
    methods: {
        prevPage() {
            this.$emit('prev-page', { formData: this.formData, pageIndex: 3 });
        },
        async nextPage() {
            this.submitted = true;

            const result = await this.validateForm();
            if (!result) {
                return;
            }

            this.formData.funcionarios
                .filter((funcionario) => funcionario.possuiRisco)
                .map((funcionario) => {
                    if (this.$refs['FormProcedimentosItems' + funcionario.id]) {
                        const records = this.$refs['FormProcedimentosItems' + funcionario.id].records;
                        funcionario.procedimentosFromSoc = records.map((record) => {
                            return {
                                prestadorId: record.prestador?.id,
                                examesIds: record.exames.map((q) => q.id),
                                diasAntecedencia: record.diasAntecedencia,
                                prestadorInformado: record.prestadorInformado,
                                horarioSemPrestador: !record.prestadorInformado ? record.horario : null,
                                itens: record.horario?.itens,
                                exameParticular: record.exameParticular
                            };
                        });
                    }
                });

            this.$emit('next-page', { formData: this.formData, pageIndex: 3 });
        },
        async validateForm() {
            return await Promise.all(
                this.formData.funcionarios
                    .filter((funcionario) => funcionario.possuiRisco)
                    .map((funcionario) => {
                        if (this.$refs['FormProcedimentosItems' + funcionario.id]) {
                            return this.$refs['FormProcedimentosItems' + funcionario.id].v$.records.$validate();
                        }
                        return true;
                    })
            ).then((validates) => validates.every((validate) => validate === true));
        },
        carregarFuncionariosLimiteSOC() {
            const funcionarios = [...this.formData.funcionarios];
            const funcionariosComRisco = funcionarios.filter((funcionario) => funcionario.possuiRisco);
            const funcionariosNaoCarregados = funcionariosComRisco.filter((item) => {
                return !this.funcionarios.some((funcionario) => funcionario.id === item.id);
            });
            const sobrando = 4 - this.funcionariosCarregando.length;
            if (sobrando) {
                this.funcionarios.push(...funcionariosNaoCarregados.slice(0, sobrando));
            }
        },
        selecionouHorario(funcionarioId, periodo) {
            this.horariosSelecionados[funcionarioId] = periodo;
        }
    }
};
</script>

<style lang="scss" scoped>
.p-card.no-border {
    box-shadow: unset;
}

.p-card.no-padding > :deep(.p-card-body) {
    padding: 0;
}
</style>
