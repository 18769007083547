<template>
    <div>
        <Divider align="left" type="dashed">
            <b>{{ this.funcionario.name }}</b>
        </Divider>

        <div v-if="loadingConfirmar" class="div-loading">
            <ProgressBar mode="indeterminate" style="height: 0.3em" />
            <Message severity="info"> Aguarde estamos inserindo os agendamentos na clínica. Esta operação pode demorar. </Message>
        </div>

        <div v-if="loadingBuscandoHorarios" class="div-loading">
            <ProgressBar mode="indeterminate" style="height: 0.3em" />
            <Message severity="info"> Aguarde. Estamos buscando os melhores horários disponíveis para a data selecionada. </Message>
        </div>

        <div :style="loadingConfirmar || loadingBuscandoHorarios ? 'opacity: 30%;' : ''" class="div-content">
            <AppLoadingWrapper v-if="loading" />

            <DataTable :value="records">
                <Column header="Particular" style="width: 5%" class="AgendarHorariostep1">
                    <template #body="{ data }">
                        <InputSwitch
                            v-if="!data.prestadorInformado"
                            id="binary"
                            v-model="data.exameParticular"
                            @change="data.exameParticular ? (data.prestadorExameParticularId = currentCompany.prestadorExameParticularId) : null"
                        />
                    </template>
                </Column>
                <Column header="Clínica" style="width: 35%" class="AgendarHorariostep2">
                    <template #body="{ index, data }">
                        <DropdownPrestadorCliente
                            v-model="data.prestador"
                            :autoLoad="!data.prestadorInformado && !data.exameParticular"
                            :class="{ 'p-invalid': submitted && v$.records.$each.$response.$errors[index].prestador.length }"
                            :disabled="data.prestadorInformado || data.exameParticular"
                            :idCidade="this.cidade.id"
                            :idCliente="this.funcionario.customer.id"
                            :idExame="getIdsExames(data)"
                            :idValue="data.prestadorExameParticularId"
                            class="w-full"
                        />
                        <small v-if="submitted && v$.records.$each.$response.$errors[index].prestador.length" class="p-error">
                            {{ v$.records.$each.$response.$errors[index].prestador[0].$message }}
                        </small>
                    </template>
                </Column>
                <Column field="nomesExames" header="Procedimentos" style="width: 30%" class="AgendarHorariostep3"></Column>
                <Column field="diasAntecedencia" header="Dias" style="width: 10%" class="AgendarHorariostep4"></Column>
                <Column header="Data e horário disponíveis" style="width: 20%" class="AgendarHorariostep5">
                    <template #body="{ index, data }">
                        <Dropdown
                            v-if="data.prestadorInformado"
                            v-model="data.horario"
                            :disabled="!podePreencherHorario(data)"
                            :loading="loadingHorarios[data.nomesExames]"
                            :options="horariosDisponiveisTratado(data.nomesExames)"
                            class="w-full"
                            placeholder="Horarios da agenda"
                            @change="changeHorario(data)"
                            @before-show="carregarSeNecessario(data)"
                        >
                            <template #value="{ placeholder, value }">
                                <span v-if="value && value.horarioChegada">
                                    {{ $filters.formatDayMonth(value.horarioChegada) }} | Entrada {{ formatTimeUtc(value.horarioChegada) }} - Saída
                                    {{ formatTimeUtc(value.horarioSaida) }} ({{ value.minutosAgendas }}
                                    min)
                                </span>
                                <span v-else>
                                    {{ placeholder }}
                                </span>
                            </template>
                            <template #option="{ option }">
                                <span>
                                    {{ $filters.formatDayMonth(option.horarioChegada) }} | Entrada {{ formatTimeUtc(option.horarioChegada) }} - Saída
                                    {{ formatTimeUtc(option.horarioSaida) }} ({{ option.minutosAgendas }}
                                    min)
                                </span>
                            </template>
                        </Dropdown>
                        <small v-if="loadingHorarios[data.nomesExames]" class="p-info"> Aguarde buscando horários na clínica. </small>
                        <Calendar
                            v-if="!data.prestadorInformado"
                            v-model="data.horario"
                            :class="{ 'p-invalid': submitted && v$.records.$each.$response.$errors[index].horario.length }"
                            :showIcon="true"
                            class="w-full"
                            dateFormat="dd/mm/yy"
                            selectionMode="single"
                        />
                        <small v-if="submitted && v$.records.$each.$response.$errors[index].horario.length" class="p-error">
                            {{ v$.records.$each.$response.$errors[index].horario[0].$message }}
                        </small>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import { getClientBase } from '@/services/http';
import DropdownPrestadorCliente from '../../../prestadores/components/DropdownPrestadorCliente.vue';
import { flatten } from 'ramda';
import * as moment from 'moment-timezone';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { getCurrentCompanyObject } from '../../../../services/store';

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: { DropdownPrestadorCliente },
    emits: ['selecionouHorario'],
    props: {
        cidade: Object,
        dataSugestaoFinal: Date,
        dataSugestaoInicial: Date,
        funcionario: Object,
        indexItem: Number,
        funcionariosCarregando: Array,
        periodo: Number,
        prestador: Object,
        procedimentos: Array,
        submitted: Boolean,
        horariosSelecionados: Object
    },
    data() {
        return {
            loading: false,
            loadingConfirmar: false,
            loadingBuscandoHorarios: false,
            records: [],
            horariosDisponiveis: {},
            loadingHorarios: {},
            recordsAgrupados: [],
            idsExames: {},
            exames: [],
            currentCompany: null
        };
    },
    validations() {
        return {
            records: {
                $each: helpers.forEach({
                    exameParticular: {},
                    prestador: {
                        required: helpers.withMessage(
                            'A clínica deve ser informado',
                            requiredIf((value, vm) => {
                                return !vm.exameParticular;
                            })
                        )
                    },
                    horario: {
                        required: helpers.withMessage('A data deve ser informada', required)
                    }
                })
            }
        };
    },
    async activated() {
        await this.loadData();
        this.currentCompany = getCurrentCompanyObject();
    },
    async mounted() {
        await this.loadData();
    },
    methods: {
        horariosDisponiveisTratado(nomesExames) {
            if (!this.horariosDisponiveis) return [];
            return (
                this.horariosDisponiveis[nomesExames]?.filter((item) => {
                    if (item.key === this.horariosSelecionados[this.funcionario.id]) {
                        return true;
                    }
                    for (const key in this.horariosSelecionados) {
                        if (key !== this.funcionario.id && this.horariosSelecionados[key].includes(item.key)) {
                            return false;
                        }
                    }

                    return true;
                }) || []
            );
        },
        formatTimeUtc(hora) {
            return moment.tz(hora, 'UTC').format('HH:mm');
        },
        async carregarSeNecessario(record) {
            if (this.loadingHorarios[record.nomesExames]) {
                return;
            }

            await this.carregarHorarios(record);
        },
        isPrimeiro(record) {
            const index = this.records.indexOf(record);
            return index <= 0;
        },
        getAnterior(record) {
            const index = this.records.indexOf(record);
            const anterior = this.records[index - 1];

            return anterior;
        },
        podePreencherHorario(record) {
            const isPrimeiro = this.isPrimeiro(record);
            if (isPrimeiro) {
                return true;
            }

            const anterior = this.getAnterior(record);
            return !!anterior.horario;
        },
        carregamentoHorarios(value) {
            const funcionarios = [...this.funcionariosCarregando];
            const index = funcionarios.findIndex((funcionario) => funcionario.id === this.funcionario.id);
            funcionarios.splice(index, 1);

            if (value) funcionarios.push(this.funcionario);
            this.$emit('update:funcionariosCarregando', funcionarios);
        },
        async loadData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get(`agendamentos/ambientes/prestador/${this.prestador.id}`);
                this.records = data;

                const examesAtendidosPrestador = flatten(this.records.map((p) => p.procedimentos));

                const idsExamesAtendidosPrestador = examesAtendidosPrestador.map((p) => p.id);

                const examesQuePrestadorAtende = this.procedimentos.filter((p) => idsExamesAtendidosPrestador.includes(p.id));

                this.records.forEach((ambiente) => {
                    const idsExames = ambiente.procedimentos.map((p) => p.id);
                    const examesDoAmbiente = examesQuePrestadorAtende.filter((p) => idsExames.includes(p.id));
                    const maiorDiasAntecedencia = this.funcionario.customer.asoRetido ? 0 : this.maiorDiasAntecedencia(examesDoAmbiente);
                    examesDoAmbiente.forEach((p) => {
                        p.diasAntecedencia = maiorDiasAntecedencia;
                    });
                });

                const maiorDiasAntecedencia = this.maiorDiasAntecedencia(examesQuePrestadorAtende);

                const examesAgrupados = [
                    {
                        diasAntecedencia: maiorDiasAntecedencia,
                        exames: examesQuePrestadorAtende.filter((p) => p.diasAntecedencia > 0)
                    },
                    {
                        diasAntecedencia: 0,
                        exames: examesQuePrestadorAtende.filter((p) => p.diasAntecedencia == 0)
                    }
                ].filter((p) => p.exames.length);

                let retorno = [];

                examesAgrupados.forEach((grupo) => {
                    const idsExames = grupo.exames.map((p) => p.id);
                    const ambientesProcedimentos = this.records.filter((p) => p.procedimentos.find((q) => idsExames.includes(q.id)));

                    retorno.push({
                        ambientes: ambientesProcedimentos.map((p) => {
                            return {
                                id: p.id,
                                nome: p.nome
                            };
                        }),
                        exames: grupo.exames.map((p) => {
                            return {
                                id: p.id,
                                nome: p.name,
                                clinical: p.clinical
                            };
                        }),
                        prestadorInformado: true,
                        prestador: {
                            id: this.prestador.id,
                            name: this.prestador.name
                        },
                        horario: null,
                        diasAntecedencia: grupo.diasAntecedencia,
                        nomesExames: grupo.exames.map((p) => p.name).join(', ')
                    });
                });

                const codigosExamesAtendidosPeloPrestador = flatten(retorno.map((p) => p.exames.map((q) => q.id)));

                const examesNaoAtendidosPeloPrestador = this.procedimentos.filter((p) => !codigosExamesAtendidosPeloPrestador.includes(p.id));

                if (examesNaoAtendidosPeloPrestador.length) {
                    examesNaoAtendidosPeloPrestador.forEach((p) => {
                        retorno.push({
                            ambientes: [],
                            exames: [
                                {
                                    id: p.id,
                                    nome: p.name
                                }
                            ],
                            prestadorInformado: false,
                            prestador: null,
                            horario: null,
                            diasAntecedencia: this.funcionario.customer.asoRetido ? 0 : p.diasAntecedencia,
                            nomesExames: p.name
                        });
                    });
                }

                this.recordsAgrupados = [
                    {
                        prestadorInformado: true,
                        title: 'Procedimentos com clínica informado',
                        items: retorno.filter((p) => p.prestadorInformado)
                    },
                    {
                        prestadorInformado: false,
                        title: 'Procedimentos sem clínica informado',
                        info: 'Procedimentos devem ser agendados diretamente com o clínica informado.',
                        items: retorno.filter((p) => !p.prestadorInformado)
                    }
                ].filter((p) => p.items.length);

                const examesAgrupamento = this.recordsAgrupados.map((p) => {
                    if (!p.prestadorInformado) {
                        return p.items.map((q) => q.exames.map((r) => r));
                    }
                });

                this.exames = flatten(examesAgrupamento);
                this.exames = this.exames.filter((p) => p?.id);

                this.records = retorno.filter((record) => record.exames.length > 0);
                await this.carregarTodosHorarios();
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$toast.add({
                    severity: 'error',
                    summary: error?.response?.data?.message || error?.message,
                    life: 3000
                });
            }
        },
        maiorDiasAntecedencia(exames) {
            return exames
                .map((p) => p.diasAntecedencia)
                .reduce((a, b) => {
                    return Math.max(a, b);
                }, Math.max());
        },
        async carregarTodosHorarios() {
            for (const record of this.records) {
                if (this.isPrimeiro(record)) {
                    await this.carregarHorarios(record);
                } else {
                    const anterior = this.getAnterior(record);
                    if (anterior.horario && record.prestadorInformado) {
                        await this.carregarHorarios(record);
                    }
                }
            }
        },
        async carregarHorarios(record) {
            this.carregamentoHorarios();
            try {
                const dto = {
                    prestadorId: this.prestador.id,
                    funcionarioId: this.funcionario.id,
                    ambienteIds: record.ambientes.map((p) => p.id),
                    examesIds: record.exames.map((p) => p.id),
                    dataInicial: this.dataSugestaoInicial,
                    dataFinal: this.dataSugestaoFinal,
                    periodo: this.periodo
                };

                if (!this.isPrimeiro(record)) {
                    const anterior = this.getAnterior(record);
                    const dataInicialMoment = moment(anterior.horario.horarioChegada).add(anterior.diasAntecedencia, 'd');
                    const dataInicial = dataInicialMoment.toDate();
                    const dataFinal = dataInicialMoment.add(3, 'd');
                    dto.dataInicial = dataInicial;
                    dto.dataFinal = dataFinal;
                }

                this.loadingHorarios[record.nomesExames] = true;
                this.loadingBuscandoHorarios = true;
                const { data } = await getClientBase().post('agendamentos/faixas-disponiveis', dto);
                this.horariosDisponiveis[record.nomesExames] = data;
                this.loadingHorarios[record.nomesExames] = false;
                this.loadingBuscandoHorarios = false;

                if (!data?.length) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Sem horários disponíveis para a data/período informado. Tente informar outra data de sugestão.',
                        life: 3000
                    });
                }
            } catch (error) {
                this.loadingHorarios[record.nomesExames] = false;
                this.loadingBuscandoHorarios = false;
                this.$toast.add({
                    severity: 'error',
                    summary: error?.response?.data?.message || error?.message,
                    life: 3000
                });
            }
            this.carregamentoHorarios();
        },
        async changeHorario(record) {
            this.$emit('selecionouHorario', record.horario.key);
            this.limparLinhasAbaixo(record);
        },
        limparLinhasAbaixo(record) {
            const posicao = this.records.indexOf(record);
            for (let index = posicao + 1; index < this.records.length; index++) {
                this.records[index].horario = null;
                this.horariosDisponiveis[this.records[index].nomesExames] = [];
            }
        },
        getIdsExames(record) {
            const found = this.idsExames[record.nomesExames];
            if (!found) {
                this.idsExames[record.nomesExames] = record.exames?.map((p) => p.id) ?? [];
            }
            return this.idsExames[record.nomesExames];
        }
    }
};
</script>

<style scoped></style>
